import React from 'react';
import '../assets/css/styles.css';
import Pupusas from '../assets/img/pupusass.jpg';
import MenuPupusas from '../assets/img/solo_pupusass_Mesa de trabajo 1.jpg';
import MenuBebidas from '../assets/img/solo_bebidass-02.jpg';
import MenuVariados from '../assets/img/solo_variadoss-03.jpg';

const Home = () => {
    return(
        <>
        <div className='pagina'>
            <div className='contenedor imageCarousel'>
                <img className='menuMesa pupusas' src={Pupusas} alt='Pupusas'></img>
                <img className='menuMesa' src={MenuPupusas} alt='Menu solo pupusas'></img>
                <img className='menuMesa' src={MenuBebidas} alt='Menu solo bebidas'></img>
                <img className='menuMesa' src={MenuVariados} alt='Menu solo variados'></img>
            </div>
        </div>
        </>
    )
}

export default Home;